import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    component: () => import('@/views'),
    meta: {
      title: '主页',
    },
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: 'https://applet.eos-heilongjiang-1.cmecloud.cn/dataScreen/ge-bg.png',
        },
      },
      {
        path: '/videoSurveillanceDetail',
        name: 'videoSurveillanceDetail',
        component: () => import('@/views//videoSurveillanceDetail.vue'),
        meta: {
          backgroundImg: require('@/assets/image/touristStructure/background.jpg'),
          auth: {
            required: true,
          },
        },
      },
      {
        path: '/geographyInfo',
        name: 'geographyInfo',
        component: () => import('@/views/geographyInfo.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: require('@/assets/image/geographicInfo/ge-bg.png'),
        },
      },
      {
        path: '/ticketData',
        name: 'ticketData',
        component: () => import('@/views/ticketData.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: require('@/assets/image/home/an-bg.jpg'),
        },
      },
      {
        path: '/environment',
        name: 'environment',
        component: () => import('@/views/environment.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: require('@/assets/image/geographicInfo/ge-bg.png'),
        },
      },
      {
        path: '/carFlow',
        name: 'carFlow',
        component: () => import('@/views/carFlow.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: require('@/assets/image/geographicInfo/ge-bg.png'),
        },
      },
      {
        path: '/visitorFlow',
        name: 'visitorFlow',
        component: () => import('@/views/visitorFlow.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: require('@/assets/image/geographicInfo/ge-bg.png'),
        },
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/views/monitor.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: require('@/assets/image/geographicInfo/ge-bg.png'),
        },
      },
      {
        path: '/publicOpinionAnalysis',
        name: 'publicOpinionAnalysis',
        component: () => import('@/views/publicOpinionAnalysis.vue'),
        meta: {
          auth: {
            required: true,
          },
          backgroundImg: require('@/assets/image/home/an-bg.jpg'),
        },
      },
      {
        path: '/dataAnalysis',
        name: 'dataAnalysis',
        component: () => import('@/views/dataAnalysis.vue'),
        redirect: { name: 'dataAnalysis-Heat' },
        meta: {
          backgroundImg: require('@/assets/image/geographicInfo/ge-bg.png'),

          auth: {
            required: true,
          },
        },
        children: [
          {
            path: 'dataAnalysis-Heat',
            name: 'dataAnalysis-Heat',
            component: () => import('@/views/dataAnalysis-Heat.vue'),
          },
          {
            path: 'dataAnalysis-passenger',
            name: 'dataAnalysis-passenger',
            component: () => import('@/views/dataAnalysis-passenger.vue'),
          },
          {
            path: 'historyPassengerFlow',
            name: 'historyPassengerFlow',
            component: () => import('@/views/historyPassengerFlow.vue'),
          },
          {
            path: 'dataAnalysis-features',
            name: 'dataAnalysis-features',
            component: () => import('@/views/dataAnalysis-features.vue'),
            meta: {
              backgroundImg: require('@/assets/image/touristStructure/bg.jpg'),

              auth: {
                required: true,
              },
            },
          },

          // {
          //   path: 'touristBehavior-travel',
          //   name: 'touristBehavior-travel',
          //   component: () => import('@/views/dataAnalysis-travel.vue'),
          // },
          // {
          //   path: 'touristBehavior-app',
          //   name: 'touristBehavior-app',
          //   component: () => import('@/views/dataAnalysis-app.vue'),
          // }
        ],
      },
    ],
  },

  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/common/400.vue'),
    name: '400',
  },
  {
    path: '/login',
    component: () => import('@/views/common/login.vue'),
    name: 'login',
  },
];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// 加载钩子判断权限
router.beforeEach(async (to, from, next) => {
  // 检查路由是否要求登录
  if (to?.meta?.auth?.required) {
    // 如果路由要求登录，那么检查是否已经登录
    if (store.getters['user/isLogin']) {
      // // 获取权限
      // const auth = store.state.user.userInfo.auth;

      // // 已登录则判断权限列表
      // if (auth?.length) {
      //   // 有权限列表继续跳转
      //   routerCheck(to, next);
      // } else {
      //   // 没有权限列表跳转登录页
      //   next({
      //     name: "login"
      //   })

      //   // 提示
      //   ElMessageBox.alert('您的权限组中没有权限，请联系管理员添加', '警告')
      // }
      next();
    } else {
      // 记录来源页面

      console.log(to.name);
      store.commit('user/loginBackUrl', to.name);

      // 未登录则跳转登录页
      next({
        name: 'login',
      });
    }
  } else {
    // 不要求登录则直接继续
    next();
  }
});
export default router;
