<template>
  <div
    class="app-button"
    :class="{
      'app-button_active': active,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    active: Boolean,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-button {
  cursor: pointer;
  transition: all 100ms;
  flex-shrink: 0;
  min-width: 52px;
  padding: 0 4px;
  box-sizing: border-box;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(6, 143, 177, 0.36);
  border: 1px solid #068fb1;
  font-size: 16px;
  color: #ffffff;

  &_active {
    background-color: rgba(255, 197, 0, 0.23);
    border: 1px solid #ffc500;
  }
}
</style>