const utils = {

  /**
   * hsl转rgb 
   */
  HSL2RGB(H = 0, S = 0, L = 0, stringMode = false,A=1) {
    H = H % 360;
    const C = (1 - Math.abs(2 * L - 1)) * S;
    const X = C * (1 - Math.abs(((H / 60) % 2) - 1));
    const m = L - C / 2;
    const vRGB = [];
    if (H >= 0 && H < 60) {
      vRGB.push(C, X, 0);
    } else if (H >= 60 && H < 120) {
      vRGB.push(X, C, 0);
    } else if (H >= 120 && H < 180) {
      vRGB.push(0, C, X);
    } else if (H >= 180 && H < 240) {
      vRGB.push(0, X, C);
    } else if (H >= 240 && H < 300) {
      vRGB.push(X, 0, C);
    } else if (H >= 300 && H < 360) {
      vRGB.push(C, 0, X);
    }
    const [vR, vG, vB] = vRGB;
    const R = 255 * (vR + m);
    const G = 255 * (vG + m);
    const B = 255 * (vB + m);

    if (stringMode) {
      return `rgba(${R},${G},${B},${A})`;
    }

    return { R, G, B };
  },
  /**
      * 时间戳转日期2022-01-02
      */
  timestampToTime(time) {
    var date = new Date(time * 1000);
    var Y = date.getFullYear() + "-";
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D =
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
    return Y + M + D;
  },

  // 获取当前年月日时分秒星期 fmt参数范围 ‘年月日'YYYY-MM-DD',时分秒'hh:mm:ss',星期'星期W'’
  formatDate(fmt) {
    const date = new Date()
    const o = {
      'Y+': date.getFullYear(),
      'M+': date.getMonth() + 1, // 月
      'D+': date.getDate(), // 日
      'h+': date.getHours(), // 时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      W: date.getDay() // 周
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, () => {
          if (k === 'W') {
            // 星期几
            const week = ['日', '一', '二', '三', '四', '五', '六']
            return week[o[k]]
          } else if (k === 'Y+' || RegExp.$1.length === 1) {
            // 年份 or 小于10不加0
            return o[k]
          } else {
            return ('00' + o[k]).substr(('' + o[k]).length) // 小于10补位0
          }
        })
      }
    }
    return fmt
  },

  getTime() {
    const date = new Date()
    return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
  },

  /**
   * 
   */
  rpx(value, base = 1920) {
    return value * document.body.clientWidth / base
  }
}
export default utils