import axios from 'axios';
import router from "@/router";
import store from '@/store'

 // import qs from 'qs'

const result = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // paramsSerializer: function (params) {
  //   return qs.stringify(params, { arrayFormat: 'brackets' })
  // },
})

// 添加请求拦截器
result.interceptors.request.use(function (config) {
  // 返回设置
  if (store.getters['user/isLogin']) {
    config.headers.token = store.state.user.userInfo.token
  }
  // 返回设置
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response 拦截器
result.interceptors.response.use(function (response) {
  switch (response.data.code) {
    case 401:
      // 401为未登录状态，一律跳转到登录页
      router.replace({
        name: 'login'
      })
      break;
    default:
      return response.data;
  }
}, function (error) {

  if (error.response.data.code === 401){
    router.replace({
      name: 'login'
    })
    return false
  }else{
    return Promise.reject(error);
  }

});


export default result