import router from "@/router"
import { ElLoading, ElMessage } from 'element-plus'
import request from '@/utils/request.js';

export default {
  namespaced: true,
  state: () => ({
    userInfo: {},
    loginBackUrl: "",
  }),
  getters: {
    isLogin(state) {
      if(state.userInfo.token){
        return true
      }else{
        return false
      }
    },
  },
  mutations: {
    /**
     * 用户登出
     * @param {*} list
     */
    async logout(state) {
      await request({
        headers:{
          token : state.userInfo.token
        },
        method: "POST",
        url: "api/user/logout",
      });
      // 清空用户信息
      state.userInfo = {}
      // 清除持久化用户信息
      localStorage.removeItem('userInfo')
      localStorage.removeItem('auth')

      // 跳转登录页
      router.replace({
        path: '/login',
      })
    },
    /**
     * 储存用户信息
     */
    setUserInfo(state, userInfo = JSON.parse(localStorage.getItem('userInfo'))) {
      // 如果用户信息存在，则设置用户信息
      if (userInfo) {
        // 储存用户信息
        state.userInfo = userInfo

        // 将用户信息持久化
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
      }
    },
    setAuth(state, auth = JSON.parse(localStorage.getItem('userInfo'))?.auth) {
      if (this.getters['user/isLogin']) {
        state.userInfo.auth = auth
        localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
        this.commit("app/setMenuList", auth, { root: true });
        this.commit("app/setNodeList", auth, { root: true });
      }
    },
    /**
     * 设置登陆返回URL
     */
    loginBackUrl(state, loginBackUrl) {
      state.loginBackUrl = loginBackUrl
    }
  },
  actions: {
    /**
     * 退出
     */
    async logout(context) {

      try {

        // 显示加载
        // const loading = ElLoading.service()

        // 执行退出
        context.commit('logout')

        // 发送请求
        request.post('api/user/logout')

        // 关闭加载
        // loading.close()
      } catch (e) {

        // 弹出提示
        ElMessage.error(e.message)
      }
    },
    /**
     * 登录
     */
    async login(context, { params: {
      account,
      password,
      code,
      geetest_challenge,
      geetest_seccode,
      geetest_validate,
      key,
      captcha,
    }, save }) {
      account;
      password;
      code;
      geetest_challenge;
      geetest_seccode;
      geetest_validate;
      key;
      captcha;
      save;

      // 获取用户信息
      const userInfo = await request({
        method: "POST",
        url: "api/user/login",
        data: {
          account,
          password,
        },
      });
      if (userInfo.code == 1){
        // 存储用户信息
        context.commit("setUserInfo", userInfo.data.userinfo)
        // 跳转索引页
        router.replace({
          name: context.state.loginBackUrl || "home",
        });
        ElMessage.success(userInfo.msg)
      }else{
        console.log(userInfo)
        ElMessage.error(userInfo.msg)
      }

      // 获取权限
      // const auth = await request.get("common/user/show-auth-list");

      // 储存权限
      // context.commit("setAuth", auth.data)

      // 记住用户名和商户编号
      // if (save === true) {
      //   localStorage.setItem('remember', JSON.stringify({ username: context.state.userInfo.user.username, code: context.state.userInfo.user.code }))
      // }
    },

    /**
     * 获取权限
     */
    async getAuth(context) {
      // 显示加载
      const loading = ElLoading.service()

      try {

        // 获取数据
        // const result = await request.get("common/user/show-auth-list");
        const result = {}

        // 设置权限
        context.commit("setAuth", result.data)

        // 关闭加载
        loading.close()
      } catch (e) {

        // 弹出提示
        ElMessage.error(e.message)
      }
    },
  },
}
