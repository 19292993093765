<template>
  <img class="app-img" :src="fullUrl" />
</template>

<script>
export default {
  components: {},
  props: {
    src: String,
  },
  computed: {
    fullUrl() {
        if (this.src.slice(0,4) == 'http'){
            return this.src;
        }else{
            return process.env.VUE_APP_IMG_URL + this.src;
        }

    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-img {
  display: block;
  object-fit: cover;
}
</style>