<template>
  <div class="app">
    <router-view />
  </div>
</template>


<script>
import { mapMutations } from "vuex";
export default {
  name: "App",
  components: {},
  data() {
    return {
      width: 1920,
      height: 1080,
    };
  },
  mounted() {
    // 初始化数据
    this.init();
  },
  methods: {
    ...mapMutations("user", ["setUserInfo"]),
    /**
     * 初始化数据
     */
    init() {
      // 储存用户数据
      this.setUserInfo();
      this.resize();
      window.addEventListener("resize", this.resize);
    },
    /**
     * 获取屏幕大小
     */
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/default.scss";
.app {
  width: 1920px;
  height: 1080px;
  transform: scale(calc(v-bind(width) / 1920), calc(v-bind(height) / 1080));
  transform-origin: 0 0;
  // 高德地图去掉logo
  .amap-logo{
    display: none;
    opacity:0 !important;
  }
}
</style>
